<template>
    <div class="admin-content">
        <h3>합격자 안내문구 미리보기</h3>

            <div class="flex-wrap right">
                    <div class="sch-wrap3 sch-box">
                        <div class="select-box form-flex-auto form-w-auto">
                            <select v-model="state.selectedSearchRecruitment" @change="methods.getNoticeOptions()">
                                <option v-for="item in state.dataTable.searchRecruitmentList" v-bind:value="item.Seq" v-bind:key="item" v-text="item.Title" />
                            </select>
                        </div>
                        <div class="select-box form-flex-auto form-w-auto">
                            <select v-model="state.selectedSearchStepType" @change="methods.getPassApplicantList()">
                                <option v-for="item in state.dataTable.searchStepTypeList" v-bind:value="item.EXAM_DIV_CD" v-bind:key="item" v-text="item.EXAM_DIV_NM" />
                            </select>
                        </div>
                        <div class="form-flex-auto">
                            <InputText type="text" v-model="state.searchApplicantNm" placeholder="지원자명" class="input-text"/>
                        </div>
                        <div class="ml10">
                        <div class="btn-wrap">
                            <button type="button" class="btn-box" @click="methods.getPassApplicantList()">조회</button>
                        </div>
                    </div>
                    </div>
                </div>

        <div class="flex-wrap half">
            <div class="col-7">

                <div class="total-box">
                    <p>Total <strong>{{state.dataTable.passUserManagementList.length}}</strong></p>
                </div>

                <DataTable
                    class="p-datatable-sm"
                    scrollHeight="497px"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    columnResizeMode="fit"
                    :paginator="true"
                    :rowHover="true"
                    :rows="7"
                    :resizable-columns="true"
                    :value="state.dataTable.passUserManagementList"
                    v-model:selection="state.selectedApplicant"
                    data-key="APCT_UNIQE">
                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="APCTP_NO" header="지원자번호" :sortable="true" style="width:12%" class="tc"/>
                    <Column field="EXAM_NO" header="수험번호" style="width:13%" class="tc"/>
                    <Column field="APCTP_KR_NM" header="성명" :sortable="true" style="width:12%" class="tc">
                    <template #body="{ data }">
                        <div @click="methods.getPassApplicantAnnouncement(data)" class="a-hover">
                            <a href='javascript:void(0);'>{{ data.APCTP_KR_NM }}</a>
                        </div>
                    </template>
                    </Column>
                    <Column field="EXAM_DIV_NM" header="지원분야" :sortable="true" style="width:13%"/>
                    <Column field="EL1" header="항목1" style="width:10%" class="tc">
                    <template #body="{ data }">
                        <div>{{ data.EL1 }}</div>
                    </template>
                    </Column>
                    <Column field="EL2" header="항목2" style="width:10%" class="tc">
                    <template #body="{ data }">
                        <div>{{ data.EL2 }}</div>
                    </template>
                    </Column>
                    <Column field="EL3" header="항목3" style="width:10%" class="tc">
                    <template #body="{ data }">
                        <div>{{ data.EL3 }}</div>
                    </template>
                    </Column>
                    <Column field="EL4" header="항목4" style="width:10%" class="tc">
                    <template #body="{ data }">
                        <div>{{ data.EL4 }}</div>
                    </template>
                    </Column>
                    <Column field="EL5" header="항목5" style="width:10%" class="tc">
                    <template #body="{ data }">
                        <div>{{ data.EL5 }}</div>
                    </template>
                    </Column>
                </DataTable>
            </div>

            <div class="col-5">
                <div class="cand-info-box" v-html="state.dataTable.passApplicantAnnouncement">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { recruitmentPassManagement } from '@/modules/recruit/REC_RC0400R.js';
import { announcementManagement } from '@/modules/recruit/REC_RC0500R.js';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
export default {
    setup() {

        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 스마트에디터
        const smartEditor = ref(null);
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 공고 목록
                searchRecruitmentList: new Array()

                // 지원분야 목록
                , searchPartList: new Array()

                // 전형구분 목록
                , searchStepTypeList: new Array()

                // 합격자 관리 목록
                , passUserManagementList: new Array()

                // 합격자 별 안내 문구
                , passApplicantAnnouncement: ''
            }

            // 선택된 공고(검색)
            , selectedSearchRecruitment: ''

            // 지원자명(검색)
            , searchApplicantNm: ''

            // 선택한 지원분야(검색)
            , selectedSearchPart: ''

            // 선택한 전형구분(검색)
            , selectedSearchStepType: ''
        });

        // 메서드
        const methods = {

            // 채용 공고 조회
            getNotice: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                recruitmentPassManagement.getNotice('RECCM001').then(result => {

                    state.dataTable.searchRecruitmentList = result.Table.rows

                }).finally(() => {

                    if(state.dataTable.searchRecruitmentList.length != 0){

                        state.selectedSearchRecruitment = state.dataTable.searchRecruitmentList[0]['Seq'];

                        methods.getNoticeOptions()
                    }
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                });
            },

            // 지원 분야 조회
            getNoticeOptions: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                recruitmentPassManagement.getNoticeOptions(state.selectedSearchRecruitment.toString()).then(result => {
                    
                    state.dataTable.searchStepTypeList = []

                    let step =  result.Table1.rows.filter(part => part.USE_YN === 'Y')

                    step.push({
                        EXAM_DIV_CD: "RC004099",
                        EXAM_DIV_NM: "불합격",
                        PREST_END_DA: "",
                        PREST_ST_DA: "",
                        SORT_SEQ: 8,
                        Seq: state.selectedSearchRecruitment,
                        SystCode: "ADM",
                        USE_YN: "Y"})

                    state.dataTable.searchStepTypeList = step

                    if(step == '') state.dataTable.announcementList = []

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                    state.selectedSearchStepType = state.dataTable.searchStepTypeList[0]['EXAM_DIV_CD']

                    methods.getPassApplicantList()
                });
            },

            // 합격자 목록 조회
            getPassApplicantList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                state.dataTable.passApplicantAnnouncement = ''

                let Seq = state.selectedSearchRecruitment.toString()
                let APCTP_KR_NM = state.searchApplicantNm
                let APCT_PT_CD = state.selectedSearchPart
                let EXAM_DIV_CD = state.selectedSearchStepType
                let ANNOUNCE_YN = state.checkAnnouncement
                
                announcementManagement.getPassApplicantList(Seq, APCTP_KR_NM, APCT_PT_CD, EXAM_DIV_CD, ANNOUNCE_YN).then(result => {
                    
                    // 합격자 목록 설정
                    if (!!result) state.dataTable.passUserManagementList = result.Table.rows

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                });
            },

            getPassApplicantAnnouncement: (data) => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                let applicantInfo = new Object()

                applicantInfo = {

                    Seq: state.selectedSearchRecruitment.toString(),
                    APCT_SEQ: data.APCT_SEQ.toString(),
                    EXAM_DIV_CD: state.selectedSearchStepType
                }

                announcementManagement.getPassApplicantAnnouncement(JSON.stringify(applicantInfo)).then(result => {

                    if(!!result) {
                        state.dataTable.passApplicantAnnouncement = result.Table.rows[0] ? result.Table.rows[0]['ANNC_TMPL_CONT'] : ''

                        state.dataTable.passApplicantAnnouncement = state.dataTable.passApplicantAnnouncement.replaceAll('$항목1$', data.EL1).replaceAll('$항목2$', data.EL2)
                        .replaceAll('$항목3$', data.EL3).replaceAll('$항목3$', data.EL3).replaceAll('$항목4$', data.EL4).replaceAll('$항목5$', data.EL5)
                        .replaceAll('$성명$', data.APCTP_KR_NM).replaceAll('$수험번호$', data.EXAM_NO).replaceAll('$지원분야$', data.EXAM_DIV_NM);
                    } else {
                        state.dataTable.passApplicantAnnouncement = ''
                    }

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                })
            }
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 안내 없음 체크 박스 클릭 이벤트
            checkNonAnnouncement: () => {
                if(state.checkAnnouncement === 'Y') state.checkAnnouncement = 'N'
                else state.checkAnnouncement = 'Y'
            }
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 공고 목록 초기 조회
            methods.getNotice()
        });

        return {
            state
            , recruitmentPassManagement
            , announcementManagement
            , methods
            , eventHandler
        }
    }
}
</script>